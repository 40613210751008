import React from 'react';
import './NewsPostCard.scss';
import Img from 'gatsby-image';
import { Link } from 'gatsby';
import cn from 'classnames';
import TwitterIcon from '@/images/twitter.inline.svg';
import linkedin from '@/images/linkedin.png';

const getFormatedDate = date =>
	new Date(date).toLocaleDateString('fr-FR', {
		day: 'numeric',
		month: 'short',
		year: 'numeric'
	});

const NewsPostCard = ({
	title,
	subtitle,
	cover,
	date,
	slug,
	location,
	isTwitterNews,
	tweetLink,
	isLinkedInNews,
	linkedInLink,
	index
}) => (
	<li
		className={cn('news-post-card', {
			'big-card': index === 0,
			'is-tweet': isTwitterNews
		})}>
		{isTwitterNews || isLinkedInNews ? (
			<a href={tweetLink || linkedInLink} rel="noopener noreferrer" target="_blank">
				<InsideArticleCard
					isTwitterNews={isTwitterNews}
					isLinkedInNews={isLinkedInNews}
					date={date}
					title={title}
					subtitle={subtitle}
					picture={cover && cover.childImageSharp.fluid}
				/>
			</a>
		) : (
			<Link to={`/${slug}`}>
				<InsideArticleCard
					date={date}
					title={title}
					subtitle={subtitle}
					picture={cover.childImageSharp && cover.childImageSharp.fluid}
				/>
			</Link>
		)}
	</li>
);

const InsideArticleCard = ({ date, title, subtitle, picture, isTwitterNews, isLinkedInNews }) => (
	<article>
		<header
			className={cn('news-picture', {
				'tweet-no-pic': !picture
			})}>
			{isTwitterNews && <TwitterIcon className="twitter-icon" width={30} height={30} fill="#fff" />}
			{isLinkedInNews && <img className="linkedin-icon" width={60} height={60} fill="#fff" src={linkedin} />}
			{picture && <Img fluid={picture} style={{ width: '100%', height: '100%' }} />}
		</header>
		<section>
			<time dateTime={date}>{getFormatedDate(date)}</time>
			<h3 className="news-card-title">{title}</h3>
			<p className="news-card-paragraph">{subtitle}</p>
		</section>
	</article>
);

export default NewsPostCard;
